import flatpickr from 'flatpickr';
// import {l10n} from

/* -------------------------------------------------------------------------- */
/*                                  Flatpickr                                 */
/* -------------------------------------------------------------------------- */

const flatpickrInit = () => {
    const { getData } = window.phoenix.utils;
    document.querySelectorAll('.datetimepicker,.datetimepicker-from,.datetimepicker-to').forEach(item => {
        const userOptions = getData(item, 'options');
        const datepicker = flatpickr(item, {
            nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
            prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
            locale: {
                firstDayOfWeek: 0,
                shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S']
            },
            monthSelectorType: 'static',
            dateFormat: 'Y-m-d',
            disableMobile: true,
            onDayCreate: (dObj, dStr, fp, dayElem) => {
                if (dayElem.dateObj.getDay() === 0 || dayElem.dateObj.getDay() === 6) {
                    dayElem.className += ' weekend-days';
                }
            },
            onChange: function(selectedDates, dateStr, instance) {
                let fromTo = item.closest('.datetimepicker-from-to');
                if(fromTo) {
                    let from = fromTo.querySelector('.datetimepicker-from');
                    let to   = fromTo.querySelector('.datetimepicker-to');
                    if( from && to ) {
                        let fromValue = from.value.replace(/\D/g, "");
                        let toValue   = to.value.replace(/\D/g, "");
                        log('fromValue', fromValue, 'toValue', toValue)
                        if(fromValue && toValue) {
                            if(Number(fromValue) > Number(toValue)) {
                                //instance.input.value = '';
                                //alert('시작일이 종료일보다 클 수 없습니다.');
                            }
                        }
                    }
                }
                log(selectedDates, dateStr, instance);
            },
            ...userOptions
        });
    });
    let setMaxDate = function(target) {
        target._flatpickr.setDate(new Date(9999, 11, 31, 23, 59));
    };
    let setFromDate = function(target, period) {
        if( period > 0) {
            period = period - 1;
        } else {
            period = period + 1;
        }
        let date = new Date().fp_incr(period);
        target._flatpickr.setDate(date);
    };
    let setToDate = function(target, period) {
        if( period > 0) {
            period = period - 1;
        } else {
            period = period + 1;
        }
        let date = new Date().fp_incr(period);
        target._flatpickr.setDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59));
    };
    document.querySelectorAll('.datetimepicker-from-to').forEach(item => {
        let from = item.querySelector('.datetimepicker-from');
        let to   = item.querySelector('.datetimepicker-to');
        item.querySelectorAll('[data-period]').forEach(el => {
            el.onclick = function() {
                let periods    = this.dataset.period.split(",");
                let fromPeriod = Number(periods[0]);
                let toPeriod   = Number(periods[1]);
                if( fromPeriod === 0) {
                    setMaxDate(from);
                } else {
                    setFromDate(from, fromPeriod);
                }
                if( toPeriod === 0) {
                    setMaxDate(to);
                } else {
                    setToDate(to, toPeriod);
                }
            }
        });
    });
};

export default flatpickrInit;
