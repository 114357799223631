const { merge } = window._;

/* -------------------------------------------------------------------------- */
/*                                   Tinymce                                  */
/* -------------------------------------------------------------------------- */
const tinymceOptions = ( userOptions ) => {
    const { getColor, getData, getItemFromStore } = window.phoenix.utils;
    return merge (
        {
            language: 'ko_KR',                  // tinymce 언어
            relative_urls: false,               // 상대경로 사용유무
            remove_script_host: false,          // relative_urls = false 일 경우 document_base_url 포함, 제외
            forced_root_block: 'div',           // 에디터 컨텐츠의 wrapping block element
            newline_behavior: 'linebreak',      // enter키 입력 시 유형
            automatic_uploads: true,            // 이미지업로드 시 자동 서버업로드
            images_upload_url: '/',             // default server upload url
            selector: '.tinymce',               // 페이지내 TinyMCE의 selector
            height: '50vh',                     // 에디터 높이
            skin: 'oxide',                      // 에디터 스킨
            menubar: false,                     // 메뉴바 사용유무
            content_style: `                    
                        .mce-content-body { 
                          color: ${getColor('black')} 
                        }
                        .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
                          color: ${getColor('gray-400')};
                          font-weight: 400;
                          font-size: 12.8px;
                        }
                        `,                      // 에디터 css 설정
            mobile: {                           // 모바일 toolbar 설정
                toolbar: [
                    { name: 'history', items: ['undo', 'redo'] },
                    {
                        name: 'formatting',
                        items: ['bold', 'italic', 'underline', 'strikethrough', 'forecolor']
                    },
                    {
                        name: 'font',
                        items: ['fontfamily', 'fontsize']
                    },
                    {
                        name: 'alignment',
                        items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify', 'lineheight']
                    },
                    { name: 'list', items: ['numlist', 'bullist'] },
                    { name: 'table', items: ['table'] },
                    { name: 'link', items: ['link'] },
                    { name: 'image', items: ['image'] },
                    { name: 'code', items: ['code'] },
                    { name: 'preview', items: ['preview'] },
                ],
            },
            statusbar: false,                   // 상태바 유무 (elementPath, wordCnt, resize 기능)
            plugins: 'link,image,lists,table,media,code,preview',               // 플러그인 정의
            theme_advanced_toolbar_align: 'center',                             // toolbar 정렬
            directionality: getItemFromStore('phoenixIsRTL') ? 'rtl' : 'ltr',   // text 방향 설정
            toolbar: [                                                          // toolbar 설정
                { name: 'history', items: ['undo', 'redo'] },
                {
                    name: 'formatting',
                    items: ['bold', 'italic', 'underline', 'strikethrough', 'forecolor']
                },
                {
                    name: 'font',
                    items: ['fontfamily', 'fontsize']
                },
                {
                    name: 'alignment',
                    items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify', 'lineheight']
                },
                { name: 'list', items: ['numlist', 'bullist'] },
                { name: 'table', items: ['table'] },
                { name: 'link', items: ['link'] },
                { name: 'image', items: ['image'] },
                { name: 'code', items: ['code'] },
                { name: 'preview', items: ['preview'] },
            ],
            font_size_formats: '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 50px',
            font_family_formats: "굴림=Gulim;궁서=Gungsuh;돋움=Dotum;바탕=Batang;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            line_height_formats: '1 1.2 1.4 1.6 1.8 2 2.2 2.4 2.6 2.8 3',
            // editor setting
            setup:  (editor) => {
                // 이미지 업로드 dialog open 시 upload tab 선택
                editor.on('OpenWindow', (e)=>{
                    let uploadTab = [...document.querySelectorAll('.tox-dialog-wrap div[role="tab"]')]
                        .filter(e=>e.textContent == '업로드')
                        .pop();
                    uploadTab?.click();
                    uploadTab?.focus();
                });
            },
        },
        userOptions
    );
}

const tinymceInit = () => {
    const { getColor, getData, getItemFromStore } = window.phoenix.utils;

    const tinymces = document.querySelectorAll('[data-tinymce]');

    const siteInfo = window.siteInfo ? window.siteInfo : {};

    log('에디터 ', siteInfo);
    if (window.tinymce) {
        tinymces.forEach(tinymceEl => {
            if(tinymceEl.getAttribute('load') === 'load') {
                return;
            }
            tinymceEl.setAttribute('load', "load");
            const userOptions = getData(tinymceEl, 'tinymce');
            console.log('userOptions', userOptions);
            log('userOptions.images_upload_url', userOptions.images_upload_url);
            log('userOptions.images_upload_url', !userOptions.images_upload_url);
            if( !userOptions.images_upload_url ) {
                log('siteConfig --> ', siteInfo);
                log('siteConfig.imageUploadUrl', siteInfo.imageUploadUrl);
                userOptions.images_upload_url = siteInfo.imageUploadUrl;
            }
            let options = tinymceOptions(userOptions)
            console.log('options', options);
            window.tinymce.init(options);
        });

        const themeController = document.body;
        if (themeController) {
            themeController.addEventListener(
                'clickControl',
                ({ detail: { control } }) => {
                    if (control === 'phoenixTheme') {
                        tinymces.forEach(tinymceEl => {
                            const instance = window.tinymce.get(tinymceEl.id);
                            instance.dom.addStyle(
                                `.mce-content-body{color: ${getColor('black')} !important;}`
                            );
                        });
                    }
                }
            );
        }
    }
};

export default tinymceInit;
